import React from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Price from "./CartForm_Components/Price";
import Description from "./CartForm_Components/Description";
import Options from "./CartForm_Components/Options";
import BulkPricing from "./CartForm_Components/BulkPricing";
import GiftCardRecipient from "./CartForm_Components/GiftCardRecipient";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TumblrShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  TumblrIcon,
  EmailIcon,
} from "react-share";

class CartForm extends React.Component {
  setPartialShip = this.setPartialShip.bind(this);
  disableButton = this.disableButton.bind(this);
  remindMe = this.remindMe.bind(this);
  changeRecipient = this.changeRecipient.bind(this);
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      selectedTests: {},
      selectedVariant: props.selectedVariant || props.variants[0],
      bulkOpen: false,
      partialShip: false,
      recipient: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.masterId !== nextProps.masterId) {
      this.setState({
        quantity: 1,
        selectedTests: {},
        selectedVariant: nextProps.variants[0],
        bulkOpen: false,
        partialShip: false,
        recipient: "",
        recipient_valid: false,
      });
    }
  }

  remindMe() {
    var id =
      this.state.selectedVariant === undefined
        ? this.props.masterId
        : this.state.selectedVariant.id;
    this.props.startLoading();
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    const that = this;
    return fetch("/react/set_reminder", {
      method: "post",
      body: JSON.stringify({ variant_id: id }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf,
      },
      credentials: "same-origin",
    })
      .then((results) => {
        if (results.ok) {
          this.props.notify(
            "success",
            "We'll let you by email know when this product is back in stock.",
          );
          return "";
        }
        throw results;
      })
      .catch((err) => {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  changeRecipient(e) {
    this.setState({
      recipient: e.target.value,
      recipient_valid: /^\S+@\S+\.\S+$/.test(e.target.value),
    });
  }

  handleSubmit = () => {
    let variants = {};
    variants[
      this.state.selectedVariant === undefined
        ? this.props.masterId
        : this.state.selectedVariant.id
    ] = this.state.quantity;

    let lineItem = {
      variants,
      product_test_types: this.state.selectedTests,
      recipient: this.state.recipient,
    };

    this.props.addToCart(lineItem);

    gtag("event", "add_to_cart", {
      currency: "USD",
      value: this.setDisplayPrice(),
      items: [
        {
          id: this.props.sku,
          name: this.props.productSale.name,
          price: this.props.productSale.price,
          quantity: this.state.quantity,
        },
      ],
    });
  };

  disableButton() {
    let countOnHand = this.props.bulkPriceInfo.count_on_hand;
    let backorderable = this.props.bulkPriceInfo.backorderable;
    return (
      (!backorderable && countOnHand <= 0) ||
      (this.props.giftCard && !this.state.recipient_valid)
    );
  }

  setPartialShip() {
    let countOnHand = this.props.bulkPriceInfo.count_on_hand;
    let backorderable = this.props.bulkPriceInfo.backorderable;
    let partialShipHTML = "";
    let jjProhibitedProductRegexp = /JJ-/i;
    let jjPurchaseLimit = this.props.jjPurchaseLimit;

    if (
      jjProhibitedProductRegexp.test(this.props.sku) &&
      jjPurchaseLimit < 999
    ) {
      partialShipHTML += `
        <i class="fa fa-warning">
          Limit <strong>${jjPurchaseLimit}</strong> Pieces Per Order
          <br><br>
        </i>
      `;
    }

    if (backorderable && countOnHand <= 0) {
      partialShipHTML += `
      <i class="fa fa-warning">
        We have <strong>0</strong>
        items on hand but this product is backorderable.
        Add to cart to backorder.
        <br><br>
      </i>
    `;
    } else if (!backorderable && countOnHand <= 0) {
      partialShipHTML += `
      <i class="fa fa-warning">
        We have <strong>0</strong>
        items on hand and this product
        is not backorderable.
        <br><br>
      </i>
    `;
    } else if (backorderable && countOnHand < this.state.quantity) {
      partialShipHTML += `
      <i class="fa fa-warning">
        We can ship <strong>${countOnHand}</strong>
        units today and the remainder at a later date.
        <br><br>
      </i>
    `;
    } else if (!backorderable && countOnHand < this.state.quantity) {
      partialShipHTML += `
      <i class="fa fa-warning">
        We have <strong>${countOnHand}</strong>
        items on hand but this product
        is not backorderable.
        <br><br>
      </i>
    `;
    }

    return ReactHtmlParser(partialShipHTML);
  }

  setOnOrder = () => {
    let onOrder = this.props.bulkPriceInfo.on_order;
    let countOnHand = this.props.bulkPriceInfo.count_on_hand;
    let onOrderText = "";

    if (countOnHand <= 0 && onOrder) {
      onOrderText = "More on the way";
    } else if (this.state.quantity > countOnHand && onOrder) {
      onOrderText = "More on the way";
    }

    return onOrderText;
  };

  setMatchingTest = () => {
    if (this.props.testTypes.length === 0) return;

    let limited = this.props.testTypes.find(
      (test) =>
        test.name.includes("Matching") && test.maximum_quantity !== null,
    );
    let unlimited = this.props.testTypes.find(
      (test) =>
        test.name.includes("Matching") && test.maximum_quantity === null,
    );
    return this.state.quantity <= limited.maximum_quantity
      ? limited
      : unlimited;
  };

  testTypes = () => {
    if (this.props.testTypes.length === 0) return [];
    let tests = this.props.testTypes.filter(
      (test) => !test.name.includes("Matching"),
    );

    let matching = this.setMatchingTest();
    if (matching !== undefined) tests.push(matching);

    return tests;
  };

  handleQuantityInput = (event) => {
    let bannedKeys = [".", "-", "+", "=", "e"];
    if (bannedKeys.includes(event.key)) return event.preventDefault();
  };

  handleQuantityChange = (value) => {
    let newQuantity = { quantity: value };

    if (this.props.bulkPriceInfo.count_on_hand < value) {
      newQuantity["partialShip"] = true;
    } else if (this.props.bulkPriceInfo.count_on_hand > value) {
      newQuantity["partialShip"] = false;
    }
    this.setState(newQuantity);
  };

  handleChange = (newState) => {
    this.setState(newState);
  };

  testTotal = () => {
    let testKeys = Object.keys(this.state.selectedTests);
    let selectedTypes = this.props.testTypes.filter((type) =>
      testKeys.includes(type.id.toString()),
    );
    let total = 0;

    selectedTypes.map((test) => {
      total += parseFloat(test.price);
    });

    return total;
  };

  setDisplayPrice = () => {
    let basePrice;
    const lineItemQuantity = this.state.quantity >= 1 ? this.state.quantity : 1;

    if (this.props.bulkPriceInfo) {
      basePrice = this.props.bulkPriceInfo.price_sheet.find(
        (price) =>
          (price.minimum_quantity <= lineItemQuantity &&
            price.maximum_quantity >= lineItemQuantity) ||
          (price.minimum_quantity <= lineItemQuantity &&
            price.maximum_quantity === null),
      ).price_per;
    } else {
      basePrice = this.props.productSale.display_price;
    }
    return ((parseFloat(basePrice) + this.testTotal()) * lineItemQuantity)
      .toFixed(2)
      .toString();
  };

  render() {
    return (
      <div id="inside-product-cart-form" data-hook="inside_product_cart_form">
        <Price
          productSale={this.props.productSale}
          variantPrice={
            this.state.selectedVariant === undefined
              ? this.props.productSale.price
              : this.state.selectedVariant.price
          }
          quantity={this.state.quantity >= 1 ? this.state.quantity : 1}
          selectedTests={this.state.selectedTests}
          testTypes={this.testTypes()}
          bulkPriceInfo={this.props.bulkPriceInfo}
          bulkPricing={this.props.bulkPricing}
          selectedVariant={this.state.selectedVariant}
          variants={this.props.variants}
          setDisplayPrice={this.setDisplayPrice()}
        />
        <Description
          quantity={this.state.quantity >= 1 ? this.state.quantity : 1}
          selectedTests={this.state.selectedTests}
          testTypes={this.testTypes()}
          matchingTest={this.setMatchingTest()}
        />
        <fieldset>
          {this.props.individualSale ? (
            <div className="control-group">
              <div id="qty-cnt">
                <label className="pull-left">QTY:</label>
                <div className="qty-cnt controls">
                  <input
                    id="quantity-input"
                    className="title fifth-width quantity-input"
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      marginLeft: "5px",
                    }}
                    type="number"
                    value={this.state.quantity}
                    min="1"
                    onChange={(event) =>
                      this.handleQuantityChange(event.target.value)
                    }
                    onKeyPress={(event) => this.handleQuantityInput(event)}
                  />
                  {this.props.bulkPricing ? (
                    <a
                      id="bulk-pricing-cta"
                      style={{ cursor: "pointer" }}
                      onClick={(newState) =>
                        this.handleChange({ bulkOpen: !this.state.bulkOpen })
                      }
                    >
                      See Bulk Pricing
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                {this.state.bulkOpen ? (
                  <BulkPricing
                    priceSheet={this.props.bulkPriceInfo.price_sheet}
                    handleChange={(newState) => this.handleChange(newState)}
                  />
                ) : null}
                <div id="partial-shipment-description">
                  <div id="partial-shipment-contents">
                    {this.setPartialShip()}
                    <div id="product-on-order">{this.setOnOrder()}</div>
                    <br />
                    {this.props.bulkPriceInfo.count_on_hand < 1 &&
                    this.props.loggedIn ? (
                      <a href="javascript:;" onClick={this.remindMe}>
                        <i className="fa fa-bullhorn">
                          Remind me when this product is back in stock
                        </i>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
              <Options
                variants={this.props.variants}
                selectedVariant={this.props.selectedVariant}
                productPrice={this.props.productSale.price}
                masterId={this.props.masterId}
                testTypes={this.testTypes()}
                matchingTest={this.setMatchingTest()}
                quantity={this.state.quantity}
                handleChange={(newState) => this.handleChange(newState)}
                updatePriceSheet={(variantId) =>
                  this.props.updatePriceSheet(variantId)
                }
              />
              {this.props.giftCard ? (
                <GiftCardRecipient
                  changeRecipient={this.changeRecipient}
                  recipient={this.state.recipient}
                />
              ) : (
                <span />
              )}
              <button
                id="add-to-cart-button"
                className="btn btn-large btn-tubedepot"
                onClick={() => this.handleSubmit()}
                disabled={this.disableButton()}
              >
                Add To Cart
              </button>
              <div className="cart-sm-buttons">
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon size={20} round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <TwitterIcon size={20} round={true} />
                </TwitterShareButton>
                {this.props.images !== undefined &&
                this.props.images.length > 0 ? (
                  <PinterestShareButton
                    url={window.location.href}
                    media={this.props.images[0].large}
                  >
                    <PinterestIcon size={20} round={true} />
                  </PinterestShareButton>
                ) : (
                  ""
                )}
                <TumblrShareButton url={window.location.href}>
                  <TumblrIcon size={20} round={true} />
                </TumblrShareButton>
                <EmailShareButton url={window.location.href}>
                  <EmailIcon size={20} round={true} />
                </EmailShareButton>
              </div>
              <a data-toggle="modal" href="#freeShippingModal">
                <p id="product-free-shipping-cta">
                  <i className="fa fa-truck" />
                  Free Shipping Over $99*
                </p>
              </a>
            </div>
          ) : (
            <div>
              <h5>Sorry, but this product is currently not for sale.</h5>
              <br />
              <br />
            </div>
          )}
        </fieldset>
      </div>
    );
  }
}

export default CartForm;
