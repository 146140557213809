import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import Image from "./Product_Components/Image";
import Details from "./Product_Components/Details";
import AlsoBought from "./Product_Components/AlsoBought";
import CartForm from "./Product_Components/CartForm";
import ReactResizeDetector from "react-resize-detector";

class ProductPage extends React.Component {
  constructor() {
    super();
    this.getSelectedVariant = this.getSelectedVariant.bind(this);
    this.state = {
      product: {},
      comments: [],
      bulkPriceInfo: {},
      alsoBought: [],
      jsonld: "",
      selectedVariant: null,
    };
  }

  getSelectedVariant = (variants) => {
    const selectedVariantId = new URLSearchParams(window.location.search).get(
      "v",
    );
    const selectedVariant =
      variants.find((variant) => variant.id == selectedVariantId) || null;
    if (selectedVariant) {
      this.updatePriceSheet(selectedVariant.id);
    }
    return selectedVariant;
  };

  componentWillReceiveProps(nextProps) {
    const that = this;
    if (!(this.props.match.params.slug == nextProps.match.params.slug)) {
      fetch("/react/products/" + nextProps.match.params.slug)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          this.setState({
            product: data,
            comments: data.comments,
            bulkPriceInfo: data.bulk_price_info,
            alsoBought: data.also_bought,
            jsonld: data.jsonld,
          });
          document.title = data.name + " | TubeDepot.com";
          gtag("event", "page_view", {
            page_path: window.location.pathname,
            product_code: data.sku,
          });
        })
        .catch(function (err) {
          err.json().then((errorMessage) => {
            that.props.notify("danger", errorMessage.error);
          });
        });
    }
  }

  componentDidMount() {
    const that = this;
    fetch("/react/products/" + this.props.match.params.slug)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        this.setState({
          product: data,
          comments: data.comments,
          bulkPriceInfo: data.bulk_price_info,
          alsoBought: data.also_bought,
          jsonld: data.jsonld,
        });
        document.title = data.name + " | TubeDepot.com";
        that.setState({
          selectedVariant: that.getSelectedVariant(data.variants),
        });
        gtag("event", "page_view", {
          page_path: window.location.pathname,
          product_code: data.sku,
        });
        gtag("event", "view_item", {
          currency: "USD",
          value: data.bulk_price_info.price_sheet[0].price_per,
          items: [
            {
              id: data.sku,
              item_id: data.sku,
              item_name: data.name,
              price: data.bulk_price_info.price_sheet[0].price_per,
            },
          ],
        });
      })
      .catch(function (err) {
        err.json().then((errorMessage) => {
          that.props.notify("danger", errorMessage.error);
        });
      });
  }

  updatePriceSheet = (variantId) => {
    fetch("/react/products/update_price_sheet/" + variantId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      })
      .then((data) => {
        this.setState({
          bulkPriceInfo: data,
        });
      })
      .catch(function (error) {
        that.props.notify(
          "danger",
          "Something went wrong. Please refresh and try again.",
        );
      });
  };

  handleProductChange = (change) => {
    this.setState(change);
  };

  setDescription = () => {
    let description = this.state.product.description;
    return ReactHtmlParser(description);
  };

  render() {
    return (
      <div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.props.updateHeight}
        >
          <div
            className="product-cnt"
            data-base-price={this.state.product.price_in_cents}
            data-master-id={this.state.master_id}
            data-ui-type={this.state.product.ui_type}
          >
            <div data-hook="product_show" itemType="http://schema.org/Product">
              <div className="row constrict title">
                <h1>{this.state.product.name}</h1>
              </div>
              <div className="span3 pull-right">
                <div className="price-cnt border">
                  {this.state.product.product_sale !== undefined ? (
                    <CartForm
                      selectedVariant={this.state.selectedVariant}
                      productSale={this.state.product.product_sale}
                      testTypes={this.state.product.test_types}
                      masterId={this.state.product.master_id}
                      variants={this.state.product.variants}
                      bulkPriceInfo={this.state.bulkPriceInfo}
                      bulkPricing={this.state.product.has_bulk_pricing}
                      updatePriceSheet={(variantId) =>
                        this.updatePriceSheet(variantId)
                      }
                      addToCart={this.props.addToCart}
                      individualSale={this.state.product.individual_sale}
                      notify={this.props.notify}
                      startLoading={this.props.startLoading}
                      stopLoading={this.props.stopLoading}
                      loggedIn={this.props.loggedIn}
                      images={this.state.product.images}
                      giftCard={this.state.product.ui_type == "gift_card"}
                      sku={this.state.product.sku}
                      jjPurchaseLimit={this.props.jjPurchaseLimit}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row constrict">
                <div style={{ minHeight: "270px" }}>
                  <div>
                    <div className="product-image" id="product-images">
                      <strong style={{ color: "gray" }}>
                        {this.props.admin ? (
                          <a
                            href={
                              "/admin" + this.props.location.pathname + "/edit"
                            }
                          >
                            {this.state.product.sku}
                          </a>
                        ) : (
                          this.state.product.sku
                        )}
                      </strong>
                      {this.state.product.images !== undefined &&
                      this.state.product.images.length > 0 ? (
                        <Image images={this.state.product.images} />
                      ) : (
                        ""
                      )}
                    </div>
                    {this.setDescription()}
                  </div>
                </div>
              </div>
              <div className="row constrict">
                {this.state.product.name !== undefined ? (
                  <AlsoBought
                    alsoBought={this.state.alsoBought}
                    productSuggestions={this.props.productSuggestions}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="row constrict">
                <div className="row product-details">
                  {this.state.product.comments !== undefined ? (
                    <Details
                      notify={this.props.notify}
                      comments={this.state.comments}
                      featuresText={this.state.product.features_text}
                      attachedFiles={this.state.product.attached_files}
                      productId={this.state.product.id}
                      handleProductChange={(change) =>
                        this.handleProductChange(change)
                      }
                      recaptchaKey={this.state.product.recaptcha_key}
                      startLoading={this.props.startLoading}
                      stopLoading={this.props.stopLoading}
                      loggedIn={this.props.loggedIn}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </ReactResizeDetector>
        <div
          className="jsonld"
          dangerouslySetInnerHTML={{ __html: this.state.jsonld.toString() }}
        ></div>
      </div>
    );
  }
}

export default ProductPage;
